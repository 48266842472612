import { useI18nContext } from '@shared-snap/i18n/i18n-react'
import type { Translation } from '@shared-snap/i18n/i18n-types'
import { useCurrentUser } from '@shared-snap/snap/components/auth/hooks/use-auth-state'
import { useDeleteUnit, useMassDeleteUnits } from '@shared-snap/snap/components/settings/hooks/use-delete-unit'
import { useMassEditUnits } from '@shared-snap/snap/components/settings/hooks/use-edit-unit'
import {
    useGroupFilter,
    useSelectedUnits,
    useStatusFilter,
    useSyncedFilter,
    useUnits,
    useUnitsFilters,
    useUnitsGroups,
    useUnitsSearch
} from '@shared-snap/snap/components/settings/hooks/use-units'
import { UI } from '@shared-snap/snap/registry/ui-elements-registry'
import { cleaningStatusOptions, pickCleaningStatusColor } from '@shared-snap/snap/utils/housekeeping-utils'
import { fetchAreas } from '@shared/area-data'
import type { AreaCleaningStatus, AreaStruct } from '@shared/firestore-structs'
import { Link, Outlet, useNavigate } from '@tanstack/react-router'
import {
    type FilterFn,
    type Row,
    createColumnHelper,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable
} from '@tanstack/react-table'
import firebase, { asFirebase } from 'app/firebase'
import { useTableCheckboxes } from 'app/hooks/use-table-checkboxes'
import { Button } from 'components/atoms/button/button'
import Input from 'components/atoms/input/input'
import { QuantityLabel } from 'components/atoms/quantity-label'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { prefix } from 'routes/__root'
import { ActionsCell } from './actions-cell'
import { ConfirmModal } from './confirm-modal'
import { FilterSelect } from './filter-select'
import { SortingHeader } from './sorting-header'
import { Table } from './table'
import { TableFilters } from './table-filters'

export function UnitsLayout() {
    return (
        <div className="flex flex-col grow shrink-0">
            <Outlet />
            <Header />
            <MassActions />
            <Content />
        </div>
    )
}

function Header() {
    const [areas] = useUnits()
    const [searchValue, setSearchValue] = useUnitsSearch()
    const [groupsFilter, setGroupsFilter] = useGroupFilter()
    const [statusFilter, setStatusFilter] = useStatusFilter()
    const [syncedFilter, setSyncedFilter] = useSyncedFilter()
    const groupsNames = useUnitsGroups()
    const {
        LL: { settingsWeb, navigation }
    } = useI18nContext()

    return (
        <>
            <div className="flex justify-between items-center mb-10">
                <div className="flex items-center gap-x-[8px]">
                    <h1 className="text-3xl font-bold">{navigation.units()}</h1>
                    <QuantityLabel quantity={areas.length} />
                </div>

                <Link to={`${prefix}/settings/units/new`}>
                    <Button>{settingsWeb.units.newUnit()}</Button>
                </Link>
            </div>
            <TableFilters
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                searchPlaceholder={settingsWeb.ruleForm.searchPlaceholder()}
                groups={groupsNames}
                groupsFilter={groupsFilter}
                setGroupsFilter={setGroupsFilter}
                syncedLabel={settingsWeb.ruleForm.syncedLabel()}
                groupsPlaceholder={settingsWeb.ruleForm.groupLabel()}
                synced={syncedFilter}
                setSynced={setSyncedFilter}
                statusFilter={statusFilter}
                setStatusFilter={setStatusFilter}
                statusLabel={settingsWeb.units.statusFilterLabel()}
            />
        </>
    )
}

const columnHelper = createColumnHelper<Partial<AreaStruct>>()

function Content() {
    const currentUser = useCurrentUser()
    const [areas, setAreas] = useUnits()
    const [sorting, setSorting] = useState<{ id: string; desc: boolean }[]>([])
    const [loading, setLoading] = useState<boolean>(true)
    const [deletingUnit, setDeletingUnits] = useState<string | null>(null)
    const [isDeletingUnit, setIsDeletingUnit] = useState(false)
    const [searchValue, setSearchValue] = useUnitsSearch()
    const unitsFilters = useUnitsFilters()
    const deleteUnit = useDeleteUnit(asFirebase(firebase))
    const navigate = useNavigate()
    const [selectedUnits, setSelectedUnits] = useSelectedUnits()

    const { selected, areAllRowsSelected, isRowSelected, toggleRowSelection, toggleSelectAll } = useTableCheckboxes(selectedUnits)
    useEffect(() => setSelectedUnits(selected), [selected, setSelectedUnits])

    const {
        LL: { settingsWeb, settings, shared }
    } = useI18nContext()

    const handleDeleteUnit = async () => {
        if (deletingUnit === null) return

        setIsDeletingUnit(true)

        try {
            await deleteUnit(deletingUnit)
            setAreas(areas.filter(area => area.key !== deletingUnit))
            setDeletingUnits(null)
        } catch (error) {
            console.error('Failed to delete unit: ', error)
        } finally {
            setIsDeletingUnit(false)
        }
    }

    const openEditModal = (row: Row<Partial<AreaStruct>>) => {
        navigate({ to: `${prefix}/settings/units/${row.original.key}` as string })
    }

    const columns = [
        columnHelper.display({
            id: 'select',
            header: ({ table }) => (
                <input
                    type="checkbox"
                    checked={areAllRowsSelected(table.getRowModel().rows)}
                    onChange={e => toggleSelectAll(table.getRowModel().rows, e.target.checked)}
                />
            ),
            cell: info => {
                const row = info.row.original
                return (
                    <label className="pr-2 pt-2 pb-2 cursor-pointer" onClick={e => e.stopPropagation()}>
                        <input
                            type="checkbox"
                            className="w-[14px] h-[14px] cursor-pointer"
                            checked={isRowSelected(row)}
                            onClick={e => e.stopPropagation()}
                            onChange={e => toggleRowSelection(row, e.target.checked)}
                        />
                    </label>
                )
            }
        }),
        columnHelper.accessor('name', {
            cell: info => (
                <UI.Text color="snap-black" size="sm" weight="bold">
                    {info.getValue()}
                </UI.Text>
            ),
            enableSorting: true,
            sortingFn: 'text',
            header: ({ column }) => <SortingHeader column={column} text={settingsWeb.units.nameColumn()} />
        }),
        columnHelper.accessor('description', {
            cell: info => <UI.Text size="xs">{info.getValue()}</UI.Text>,
            enableSorting: true,
            sortingFn: 'text',
            header: ({ column }) => <SortingHeader column={column} text={settings.units.descriptionLabel()} />
        }),
        columnHelper.accessor('group', {
            cell: info => info.getValue(),
            enableSorting: true,
            sortingFn: 'text',
            header: ({ column }) => <SortingHeader column={column} text={settings.units.groupLabel()} />
        }),
        columnHelper.accessor('cleaningStatus', {
            cell: info => {
                const row = info.row.original
                const cleaningStatus = row.cleaningStatus || 'clean'
                const cleaningStatusOption = cleaningStatusOptions.find(option => option.value === cleaningStatus)
                const occupancy = row.occupancy || 'vacant'
                const cleaningStatusColor = pickCleaningStatusColor({ cleaningStatus, occupancy })

                return (
                    <div className="flex items-center gap-x-2">
                        <div className={`w-[7px] h-[7px] rounded bg-${cleaningStatusColor}`} />
                        <UI.Text>
                            {shared.cleaningStatuses[cleaningStatusOption?.label as keyof Translation['shared']['cleaningStatuses']]()}
                        </UI.Text>
                    </div>
                )
            },
            enableSorting: true,
            sortingFn: 'text',
            header: ({ column }) => <SortingHeader column={column} text={settingsWeb.units.cleaningStatusColumn()} />
        }),
        columnHelper.accessor('updated', {
            cell: info => moment(info.getValue()).format('MMM D, YYYY h:mm A'),
            enableSorting: true,
            sortingFn: 'datetime',
            header: ({ column }) => <SortingHeader column={column} text={settingsWeb.units.updatedColumn()} />
        }),
        columnHelper.accessor('synced', {
            cell: info => {
                const row = info.getValue() || false

                return row ? shared.yes() : shared.no()
            },
            header: settingsWeb.units.syncedColumn()
        }),
        columnHelper.display({
            id: 'actions',
            header: settingsWeb.units.actionsColumn(),
            cell: ({ row }) => <ActionsCell onEdit={() => openEditModal(row)} onDelete={() => setDeletingUnits(row.original.key ?? null)} />
        })
    ]

    const customFilterFunction: FilterFn<Partial<AreaStruct>> = (row, columnId, filterValue) => {
        if (filterValue === null || filterValue === 'all' || filterValue.length === 0) {
            return true
        }

        const value = row.getValue(columnId)

        if (typeof filterValue === 'boolean') {
            return value === filterValue
        }

        return filterValue.includes(value)
    }

    const table = useReactTable({
        data: areas,
        columns,
        state: {
            globalFilter: searchValue,
            columnFilters: unitsFilters,
            sorting
        },
        defaultColumn: {
            filterFn: customFilterFunction
        },
        onSortingChange: setSorting,
        onGlobalFilterChange: setSearchValue,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel()
    })

    useEffect(() => {
        const loadAreas = async () => {
            setLoading(true)
            try {
                const fetchedAreas = await fetchAreas(asFirebase(firebase), currentUser)
                const normalizedAreas = fetchedAreas.map(area => ({
                    ...area,
                    synced: area.synced ?? false
                }))
                setAreas(normalizedAreas)
            } catch (error) {
                console.error('Failed to fetch areas:', error)
            } finally {
                setLoading(false)
            }
        }

        loadAreas()
    }, [currentUser, setAreas])

    if (loading) {
        return (
            <div className="flex grow items-center justify-center">
                <UI.Loader />
            </div>
        )
    }

    return (
        <>
            {selected.length > 0 && <UI.Text weight="semibold">{settingsWeb.units.selectedCount({ count: selected.length })}</UI.Text>}
            <Table table={table} rowOnCLick={openEditModal} />
            <ConfirmModal
                isOpen={deletingUnit !== null}
                onClose={() => setDeletingUnits(null)}
                onConfirm={handleDeleteUnit}
                headerText={settingsWeb.units.deleteUnitHeader()}
                bodytext={settingsWeb.units.deleteConfirmModal({ count: 1 })}
                isLoading={isDeletingUnit}
            />
        </>
    )
}

function MassActions() {
    const [selectedUnits] = useSelectedUnits()
    const [description, setDescription] = useState<string>('')
    const [group, setGroup] = useState<string>('')
    const [cleaningStatus, setCleaningStatus] = useState<AreaCleaningStatus | undefined>(undefined)
    const [processingMassAction, setProcessingMassAction] = useState<boolean>(false)
    const editUnits = useMassEditUnits(asFirebase(firebase))
    const deleteUnits = useMassDeleteUnits(asFirebase(firebase))
    const {
        LL: { settingsWeb, settings, shared }
    } = useI18nContext()

    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState<boolean>(false)
    const [editModalIsOpen, setEditModalIsOpen] = useState<boolean>(false)

    const clearEditInfo = () => {
        setDescription('')
        setGroup('')
        setCleaningStatus(undefined)
    }

    const handleDeleteUnits = async () => {
        setProcessingMassAction(true)
        await deleteUnits(selectedUnits)
        clearEditInfo()
        setDeleteModalIsOpen(false)
        setProcessingMassAction(false)
    }

    const handleEditUnits = async () => {
        setProcessingMassAction(true)
        await editUnits(selectedUnits, { description, group, cleaningStatus })
        clearEditInfo()
        setEditModalIsOpen(false)
        setProcessingMassAction(false)
    }

    if (!selectedUnits.length) return null

    return (
        <div className="flex my-8 w-full gap-x-10 justify-between">
            <div className="flex gap-x-10 w-full">
                <Input
                    className="w-full max-w-[200px]"
                    placeholder={settings.units.descriptionPlaceholder()}
                    initialValue={description}
                    onChange={setDescription}
                    name="description"
                />
                <Input
                    className="w-full max-w-[200px]"
                    placeholder={settingsWeb.units.groupPlaceholder()}
                    initialValue={group}
                    onChange={setGroup}
                    name="group"
                />
                <div className="w-full max-w-[200px]">
                    <FilterSelect
                        value={
                            cleaningStatus
                                ? [
                                      {
                                          value: cleaningStatus,
                                          label:
                                              cleaningStatus === 'all'
                                                  ? shared.all()
                                                  : shared.cleaningStatuses[
                                                        cleaningStatusOptions.find(status => status.value === cleaningStatus)
                                                            ?.label as keyof Translation['shared']['cleaningStatuses']
                                                    ]()
                                      }
                                  ]
                                : []
                        }
                        options={cleaningStatusOptions.map(status => ({
                            value: status.value,
                            label: shared.cleaningStatuses[status.label]()
                        }))}
                        onChange={value => setCleaningStatus(value as AreaCleaningStatus)}
                    />
                </div>
                <UI.Button
                    disabled={!description && !group && !cleaningStatus}
                    text={shared.save()}
                    onClick={() => setEditModalIsOpen(true)}
                />
            </div>
            <UI.Button className="grow shrink-0" text={settingsWeb.units.deleteAllBtn()} onClick={() => setDeleteModalIsOpen(true)} />

            <ConfirmModal
                isOpen={deleteModalIsOpen}
                onClose={() => setDeleteModalIsOpen(false)}
                onConfirm={handleDeleteUnits}
                headerText={settingsWeb.units.deleteUnitHeader()}
                bodytext={settingsWeb.units.deleteConfirmModal({ count: selectedUnits.length })}
                isLoading={processingMassAction}
            />
            <ConfirmModal
                isOpen={editModalIsOpen}
                onClose={() => setEditModalIsOpen(false)}
                onConfirm={handleEditUnits}
                headerText={settingsWeb.units.massChangingHeader()}
                bodytext={settingsWeb.units.changeConfirmModal({ count: selectedUnits.length })}
                isLoading={processingMassAction}
            />
        </div>
    )
}
