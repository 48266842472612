import type { RuleStruct } from '@shared/firestore-structs'
import { atom, selector } from 'recoil'

export const rulesAtom = atom<RuleStruct[]>({
    key: 'rules-atom',
    default: []
})

export const currentRuleWizardStep = atom<number>({
    key: 'rule-wizard-steps',
    default: 1
})
