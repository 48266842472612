import { useI18nContext } from '@shared-snap/i18n/i18n-react'
import { useCurrentOrg } from '@shared-snap/snap/components/auth/hooks/use-auth-state'
import { useDeleteRule, useRules } from '@shared-snap/snap/components/settings/hooks/use-rules'
import { UI } from '@shared-snap/snap/registry/ui-elements-registry'
import type { RuleStruct } from '@shared/firestore-structs'
import { getRulesQuery } from '@shared/queries/data'
import { Link, useNavigate } from '@tanstack/react-router'
import {
    type RowSelectionState,
    createColumnHelper,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable
} from '@tanstack/react-table'
import firebase, { asFirebase } from 'app/firebase'
import { Button } from 'components/atoms/button/button'
import { QuantityLabel } from 'components/atoms/quantity-label'
import { SearchInput } from 'components/atoms/search-input'
import moment from 'moment'
import { type HTMLProps, useEffect, useMemo, useRef, useState } from 'react'
import { prefix } from 'routes/__root'
import { ActionsCell } from './actions-cell'
import { ConfirmModal } from './confirm-modal'
import { SortingHeader } from './sorting-header'
import { Table } from './table'

const columnHelper = createColumnHelper<RuleStruct>()

export function RulesLayout() {
    return (
        <div className="flex flex-col grow shrink-0">
            <Header />
            <Content />
        </div>
    )
}

function Header() {
    const [rules] = useRules()
    const {
        LL: { settingsWeb, navigation }
    } = useI18nContext()

    return (
        <div className="flex justify-between items-center mb-10">
            <div className="flex items-center gap-x-[8px]">
                <h1 className="text-3xl font-bold">{navigation.rules()}</h1>
                <QuantityLabel quantity={rules.length} />
            </div>

            <Link to={`${prefix}/settings/rules/new`} search={{ step: 1 }}>
                <Button>{settingsWeb.rules.newRule()}</Button>
            </Link>
        </div>
    )
}

function IndeterminateCheckbox({ indeterminate, className = '', ...rest }: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) {
    const ref = useRef<HTMLInputElement>(null)

    useEffect(() => {
        if (typeof indeterminate === 'boolean' && ref.current) {
            ref.current.indeterminate = !rest.checked && indeterminate
        }
    }, [ref, indeterminate, rest.checked])

    return <input type="checkbox" ref={ref} className={className + ' cursor-pointer'} {...rest} />
}

function Content() {
    const currentOrganization = useCurrentOrg()
    const [rules, setRules] = useState<RuleStruct[]>([])
    const [loading, setLoading] = useState(false)
    const [searchValue, setSearchValue] = useState('')
    const [isMassDeleteModalOpen, setIsMassDeleteModalOpen] = useState(false)
    const [deleteRule, setDeleteRule] = useState<RuleStruct | null>(null)
    const [processingMassDelete, setProcessingMassDelete] = useState(false)
    const [processingDeleteRule, setProcessingDeleteRule] = useState(false)
    const [rowSelection, setRowSelection] = useState<RowSelectionState>({}) //manage your own row selection state
    const [sorting, setSorting] = useState<{ id: string; desc: boolean }[]>([])
    const deleteRules = useDeleteRule(asFirebase(firebase))
    const navigate = useNavigate()
    const {
        LL: { settingsWeb }
    } = useI18nContext()

    const handleDeleteRules = async () => {
        setProcessingMassDelete(true)
        try {
            await deleteRules(rules.filter(rule => rowSelection[rule.key]))
            setRules(prev => prev.filter(rule => !rowSelection[rule.key]))
            setRowSelection({})
            setIsMassDeleteModalOpen(false)
        } finally {
            setProcessingMassDelete(false)
        }
    }

    const handleDeleteRule = async () => {
        if (!deleteRule) return

        setProcessingDeleteRule(true)

        try {
            await deleteRules([deleteRule])
            setRules(prev => prev.filter(rule => rule.key !== deleteRule.key))
            setDeleteRule(null)
        } finally {
            setProcessingDeleteRule(false)
        }
    }

    const handleOpenEditRule = (ruleKey: string, step = 1) => {
        navigate({ to: `${prefix}/settings/rules/${ruleKey}` as string, search: { step } })
    }

    const columns = useMemo(
        () => [
            columnHelper.display({
                id: 'select',
                header: ({ table }) => (
                    <div className="px-4">
                        <IndeterminateCheckbox
                            name={'selectAll'}
                            checked={table.getIsAllRowsSelected()}
                            indeterminate={table.getIsSomeRowsSelected()}
                            onClick={e => {
                                e.stopPropagation()
                            }}
                            onChange={table.getToggleAllRowsSelectedHandler()}
                        />
                    </div>
                ),

                cell: ({ row }) => (
                    <div className="px-4">
                        <IndeterminateCheckbox
                            name={`selectRow-${row.id}`}
                            checked={row.getIsSelected()}
                            disabled={!row.getCanSelect()}
                            indeterminate={row.getIsSomeSelected()}
                            onClick={e => e.stopPropagation()}
                            onChange={row.getToggleSelectedHandler()}
                        />
                    </div>
                )
            }),
            columnHelper.accessor('name', {
                cell: info => (
                    <div onClick={() => handleOpenEditRule(info.row.original.key, 1)}>
                        <UI.Text color="snap-black" size="sm" weight="bold">
                            {info.getValue()}
                        </UI.Text>
                    </div>
                ),
                enableSorting: true,
                sortingFn: 'text',
                header: ({ column }) => <SortingHeader column={column} text={settingsWeb.rules.nameColumn()} />
            }),
            columnHelper.accessor('description', {
                cell: info => <UI.Text size="xs">{info.getValue()}</UI.Text>,
                enableSorting: true,
                sortingFn: 'text',
                header: ({ column }) => <SortingHeader column={column} text={settingsWeb.rules.descriptionColumn()} />
            }),
            columnHelper.accessor('areas', {
                cell: info => (
                    <div onClick={() => handleOpenEditRule(info.row.original.key, 3)}>
                        <QuantityLabel quantity={info.getValue().length} />
                    </div>
                ),
                enableSorting: true,
                sortingFn: (rowA, rowB, columnId) => {
                    const valueA = rowA.getValue(columnId)
                    const valueB = rowB.getValue(columnId)

                    if (!Array.isArray(valueA) || !Array.isArray(valueB)) {
                        return 0
                    }

                    return valueA.length - valueB.length
                },
                header: ({ column }) => <SortingHeader column={column} text={settingsWeb.rules.areasColumn()} />
            }),
            columnHelper.accessor('repeatType', {
                cell: info => settingsWeb.rulesRepeatType[info.getValue()](),
                enableSorting: true,
                sortingFn: 'text',
                header: ({ column }) => <SortingHeader column={column} text={settingsWeb.rules.typeColumn()} />
            }),
            columnHelper.accessor('start', {
                cell: info => moment(info.getValue()).format('MMMM D, YYYY'),
                enableSorting: true,
                sortingFn: 'datetime',
                header: ({ column }) => <SortingHeader column={column} text={settingsWeb.rules.startDateColumn()} />
            }),
            columnHelper.display({
                id: 'actions',
                header: settingsWeb.rules.actionsColumn(),
                cell: ({ row }) => (
                    <ActionsCell onEdit={() => handleOpenEditRule(row.original.key)} onDelete={() => setDeleteRule(row.original)} />
                )
            })
        ],
        [settingsWeb.rules]
    )

    const table = useReactTable({
        data: rules,
        columns,
        state: {
            globalFilter: searchValue,
            rowSelection,
            sorting
        },
        enableRowSelection: true,
        onRowSelectionChange: setRowSelection,
        onGlobalFilterChange: setSearchValue,
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        enableMultiRowSelection: true,
        getRowId: row => row.key // Use unique key for each row
    })

    useEffect(() => {
        const loadRules = async () => {
            setLoading(true)

            try {
                const rulesRef = await getRulesQuery(asFirebase(firebase), currentOrganization.key).get()
                const fetchedRules = rulesRef.docs.map(doc => doc.data())

                setRules(fetchedRules)
            } finally {
                setLoading(false)
            }
        }

        loadRules()
    }, [])

    if (loading) {
        return (
            <div className="flex grow items-center justify-center">
                <UI.Loader />
            </div>
        )
    }

    const selectedRules = Object.keys(rowSelection)
    return (
        <>
            <div className="mb-10">
                <SearchInput placeholder={settingsWeb.rules.searchPlaceholder()} value={searchValue} onChange={setSearchValue} />
            </div>
            {selectedRules.length > 0 && (
                <>
                    <Button className="w-fit mb-4" onClick={() => setIsMassDeleteModalOpen(true)}>
                        {settingsWeb.rules.deleteAllBtn()}
                    </Button>
                    <UI.Text weight="semibold">{settingsWeb.rules.selectedCount({ count: selectedRules.length })}</UI.Text>
                </>
            )}
            <Table table={table} />
            <ConfirmModal
                isOpen={isMassDeleteModalOpen}
                onClose={() => setIsMassDeleteModalOpen(false)}
                onConfirm={handleDeleteRules}
                headerText={settingsWeb.rules.deleteHeader()}
                bodytext={settingsWeb.rules.massDeleteConfirmModal({ count: selectedRules.length })}
                isLoading={processingMassDelete}
            />
            <ConfirmModal
                isOpen={deleteRule !== null}
                onClose={() => setDeleteRule(null)}
                onConfirm={handleDeleteRule}
                headerText={settingsWeb.rules.deleteHeader()}
                bodytext={settingsWeb.rules.singleDeleteConfirmModal()}
                isLoading={processingDeleteRule}
            />
        </>
    )
}
