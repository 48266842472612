import { useI18nContext } from '@shared-snap/i18n/i18n-react'
import { UI } from '@shared-snap/snap/registry/ui-elements-registry'

export const defaultPageSizeOptions = [10, 25, 100, 250, 500]

interface PaginationProps {
    pageSize: number
    totalVisible: number
    totalRows: number
    currentPage: number
    totalPages: number
    onPageChange: (page: number) => void
    onNextPage?: () => void
    onPrevPage?: () => void
    onPageSizeSelect?: (pageSize: number) => void
    pageSizeOptions?: number[]
}

export function Pagination({
    pageSize,
    totalVisible,
    totalRows,
    currentPage,
    totalPages,
    onPageChange,
    onNextPage,
    onPrevPage,
    onPageSizeSelect,
    pageSizeOptions = defaultPageSizeOptions
}: PaginationProps) {
    const {
        LL: {
            settingsWeb,
            issues: { pagination }
        }
    } = useI18nContext()
    const paginationBtnStyle = 'py-1 bg-gray-200 rounded-md hover:bg-gray-300 w-8 disabled:pointer-events-none disabled:opacity-50'

    const handlePageChange = (page: number) => {
        if (page >= 1 && page <= totalPages) {
            onPageChange(page)
        }
    }

    const handleGoToNextPage = () => {
        if (onNextPage) {
            onNextPage()
        } else {
            handlePageChange(currentPage + 1)
        }
    }

    const handleGoToPrevPage = () => {
        if (onPrevPage) {
            onPrevPage()
        } else {
            handlePageChange(currentPage - 1)
        }
    }

    return (
        <div className="flex flex-row justify-between mt-4 mb-[60px] w-full">
            {pageSize < totalRows && (
                <div className="flex flex-row gap-4 items-center">
                    <button className={paginationBtnStyle} onClick={() => handlePageChange(1)} disabled={currentPage === 1}>
                        {'<<'}
                    </button>

                    <button className={paginationBtnStyle} onClick={handleGoToPrevPage} disabled={currentPage === 1}>
                        {'<'}
                    </button>

                    <button className={paginationBtnStyle} onClick={handleGoToNextPage} disabled={currentPage === totalPages}>
                        {'>'}
                    </button>

                    <button
                        className={paginationBtnStyle}
                        onClick={() => handlePageChange(totalPages)}
                        disabled={currentPage === totalPages}>
                        {'>>'}
                    </button>

                    <div className="flex gap-1 items-center">
                        <UI.Text size="md" weight="normal">
                            {pagination.page()}
                        </UI.Text>
                        <UI.Text size="md">{pagination.currentPage({ currentPage, totalPages })}</UI.Text>
                    </div>
                </div>
            )}
            {/** select  to change pagesize */}
            {onPageSizeSelect && (
                <div className="flex gap-4 items-center pr-10">
                    <select
                        value={pageSize}
                        onChange={e => {
                            const pageSize = Number(e.target.value)
                            onPageSizeSelect?.(pageSize)
                        }}
                        className="h-[30px] min-w-[80px] border-b w-16 border-snap-light-silver focus:outline-none">
                        {pageSizeOptions
                            .sort((a, b) => a - b)
                            .map(size => (
                                <option key={size} value={size}>
                                    {size}
                                </option>
                            ))}
                    </select>
                    <UI.Text size="md" weight="normal" color="snap-gray">
                        {pagination.showingRows({ count: totalVisible, total: totalRows })}
                    </UI.Text>
                </div>
            )}
        </div>
    )
}
