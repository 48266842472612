import { type Row, type Table as TableInterface, flexRender } from '@tanstack/react-table'
import { Pagination } from 'components/atoms/pagination'
import { useMemo } from 'react'

export function Table<T>({
    table,
    rowOnCLick,
    pageSelectionOptions
}: {
    table: TableInterface<T>
    pageSelectionOptions?: number[]
    rowOnCLick?: (row: Row<T>) => void
}) {
    'use no memo'

    // biome-ignore lint/correctness/useExhaustiveDependencies: biome is wrong table functions needed for item to rerender
    const paginationState = useMemo(() => {
        const { pageIndex, pageSize } = table.getState().pagination
        return {
            pageSize,
            currentPage: pageIndex + 1,
            totalRows: table.getFilteredRowModel().rows.length,
            totalVisible: table.getRowModel().rows.length,
            totalPages: table.getPageCount()
        }
    }, [
        table.getState().pagination.pageIndex,
        table.getState().pagination.pageSize,
        table.getFilteredRowModel().rows.length,
        table.getRowModel().rows.length,
        table.getPageCount()
    ])

    return (
        <div>
            <table className="w-full">
                <thead>
                    {table.getHeaderGroups().map(headerGroup => (
                        <tr
                            className="h-[52px] border-b border-snap-light-gray text-[14px] text-snap-silver font-medium"
                            key={headerGroup.id}>
                            {headerGroup.headers.map((header, i) => (
                                <th className={`text-start ${i !== headerGroup.headers.length - 1 ? 'pr-8' : ''}`} key={header.id}>
                                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody>
                    {table.getRowModel().rows.map(row => (
                        <tr
                            onClick={() => rowOnCLick?.(row)}
                            className="h-[52px] border-b border-snap-light-gray text-snap-dark-gray text-[14px] hover:bg-snap-teal-100 cursor-pointer"
                            key={row.id}>
                            {row.getVisibleCells().map(cell => (
                                <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>

            <Pagination
                {...paginationState}
                onPageChange={(page: number) => table.setPageIndex(page - 1)}
                onNextPage={table.nextPage}
                onPrevPage={table.previousPage}
                onPageSizeSelect={pageSize => table.setPageSize(pageSize)}
                pageSizeOptions={pageSelectionOptions}
            />
        </div>
    )
}
