import { useCurrentOrg } from '@shared-snap/snap/components/auth/hooks/use-auth-state'
import { useReportsState } from '@shared-snap/snap/components/reports/hooks/use-reports-state'
import { UI } from '@shared-snap/snap/registry/ui-elements-registry'
import type { OrgStruct } from '@shared/firestore-structs'
import { ReportsFilter } from 'components/molecules/reports/reports-filter/reports-filter'
import { SupersetDashboard } from 'components/molecules/reports/superset/superset-dashboard'
import type { SupersetDateFilter } from 'components/molecules/reports/superset/superset-filters'
import moment from 'moment-timezone'
import React, { useContext, useEffect, useMemo, useState } from 'react'

const dateFilters = ['Today', 'Yesterday', 'This month', 'Last month', 'This week']

// These values are hardcoded because we use them to tell the server what
// Superset native filter we want. Filters have both an ID and a name, and
// the name will be the same between environments, but IDs not necessarily.
const createSupersetFilter = (currentOrg: OrgStruct, dateFrom: number, dateTo: number): SupersetDateFilter => ({
    filterName: 'Dates', // Comes from the dashboard config.
    column: 'start_date', // Must be actual dataset column name, NOT label or filter name!
    dateFrom: moment.tz(dateFrom, currentOrg.timezone).startOf('day'),
    dateTo: moment.tz(dateTo, currentOrg.timezone).startOf('day')
})

export function SupersetHousekeepingReport() {
    const { startDate, endDate } = useReportsState()
    const currentOrganization = useCurrentOrg()
    const [loading, setLoading] = useState(true)

    if (!currentOrganization) throw new Error('Not logged in')

    const [supersetFilter, setSupersetFilter] = useState<SupersetDateFilter | null>(null)

    useEffect(() => {
        const getData = async () => {
            console.log('Updating superset filters: ', startDate.format(), 'to', endDate.format())
            setLoading(true)
            const updatedFilter = createSupersetFilter(currentOrganization!, startDate.valueOf(), endDate.valueOf())
            setSupersetFilter(updatedFilter)
            setTimeout(() => {
                setLoading(false)
            }, 500)
        }

        getData()
    }, [startDate, endDate])

    return (
        <>
            <ReportsFilter />
            {supersetFilter !== null && !loading ? <SupersetDashboard reportName="Housekeeping" filter={supersetFilter} /> : <UI.Loader />}
        </>
    )
}
