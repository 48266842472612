import { useI18nContext } from '@shared-snap/i18n/i18n-react'
import { useCurrentUser } from '@shared-snap/snap/components/auth/hooks/use-auth-state'
import { UI } from '@shared-snap/snap/registry/ui-elements-registry'
import { fetchAreas } from '@shared/area-data'
import type { AreaStruct } from '@shared/firestore-structs'
import {
    type FilterFn,
    createColumnHelper,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable
} from '@tanstack/react-table'
import firebase, { asFirebase } from 'app/firebase'
import { SortingHeader } from 'components/layouts/settings/sorting-header'
import { Table } from 'components/layouts/settings/table'
import { TableFilters } from 'components/layouts/settings/table-filters'
import { useEffect, useMemo, useState } from 'react'
import { useFormContext } from 'react-hook-form'

const columnHelper = createColumnHelper<Partial<AreaStruct>>()

export function AssignStep() {
    const [areas, setAreas] = useState<Partial<AreaStruct>[]>([])
    const [groupsNames, setGroupsNames] = useState<string[]>([])
    const [loading, setLoading] = useState(false)
    const [searchValue, setSearchValue] = useState('')
    const currentUser = useCurrentUser()
    const { watch, setValue } = useFormContext()
    const formAreas: string[] = watch('areas')
    const [groupsFilter, setGroupsFilter] = useState<string[]>([])
    const [syncedFilter, setSyncedFilter] = useState<boolean | null>(null)
    const unitsFilters = useMemo(
        () => [
            { id: 'group', value: groupsFilter },
            { id: 'synced', value: syncedFilter }
        ],
        [groupsFilter, syncedFilter]
    )
    const {
        LL: { settingsWeb, shared }
    } = useI18nContext()

    const handleToggleRowSelection = (rowKey: string, isSelected: boolean) => {
        const updatedKeys = isSelected ? [...formAreas, rowKey] : formAreas.filter(key => key !== rowKey)

        setValue('areas', updatedKeys)
    }

    const handleToggleSelectAll = (rows: Partial<AreaStruct>[], isSelected: boolean) => {
        const allKeys = rows.map(row => row.key)
        const updatedKeys = isSelected ? Array.from(new Set([...formAreas, ...allKeys])) : formAreas.filter(key => !allKeys.includes(key))

        setValue('areas', updatedKeys)
    }

    const columns = [
        columnHelper.display({
            id: 'select',
            header: () => (
                <input
                    type="checkbox"
                    checked={areas.length > 0 && formAreas.length === areas.length}
                    onChange={e => handleToggleSelectAll(areas, e.target.checked)}
                />
            ),
            cell: info => {
                const row = info.row.original
                return (
                    <input
                        type="checkbox"
                        onClick={e => e.stopPropagation()}
                        checked={row.key ? formAreas.includes(row.key) : false}
                        onChange={e => row.key && handleToggleRowSelection(row.key, e.target.checked)}
                    />
                )
            }
        }),
        columnHelper.accessor('name', {
            cell: info => (
                <UI.Text color="snap-black" size="sm" weight="bold">
                    {info.getValue()}
                </UI.Text>
            ),
            enableSorting: true,
            sortingFn: 'text',
            header: ({ column }) => <SortingHeader column={column} text={settingsWeb.ruleForm.nameLabel()} />
        }),
        columnHelper.accessor('description', {
            cell: info => <UI.Text size="xs">{info.getValue()}</UI.Text>,
            enableSorting: true,
            sortingFn: 'text',
            header: ({ column }) => <SortingHeader column={column} text={settingsWeb.ruleForm.descriptionLabel()} />
        }),
        columnHelper.accessor('group', {
            cell: info => info.getValue(),
            enableSorting: true,
            sortingFn: 'text',
            header: ({ column }) => <SortingHeader column={column} text={settingsWeb.ruleForm.groupLabel()} />
        }),
        columnHelper.accessor('synced', {
            cell: info => {
                const row = info.getValue() || false

                return row ? shared.yes() : shared.no()
            },
            header: settingsWeb.ruleForm.syncedLabel()
        })
    ]

    const customFilterFunction: FilterFn<Partial<AreaStruct>> = (row, columnId, filterValue) => {
        if (filterValue === null || filterValue === 'all' || filterValue.length === 0) {
            return true
        }

        const value = row.getValue(columnId)

        if (typeof filterValue === 'boolean') {
            return value === filterValue
        }

        return filterValue.includes(value)
    }

    const table = useReactTable({
        data: areas,
        columns,
        state: {
            globalFilter: searchValue,
            columnFilters: unitsFilters
        },
        defaultColumn: {
            filterFn: customFilterFunction
        },
        getCoreRowModel: getCoreRowModel(),
        onGlobalFilterChange: setSearchValue,
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel()
    })

    useEffect(() => {
        const loadAreas = async () => {
            setLoading(true)
            try {
                const fetchedAreas = await fetchAreas(asFirebase(firebase), currentUser)
                const normalizedAreas = fetchedAreas.map(area => ({
                    ...area,
                    synced: area.synced ?? false
                }))
                setAreas(normalizedAreas)
                setGroupsNames(Array.from(new Set(normalizedAreas.map(unit => unit.group ?? ''))))
            } finally {
                setLoading(false)
            }
        }

        loadAreas()
    }, [])

    if (loading) {
        return (
            <div className="flex grow items-center justify-center">
                <UI.Loader />
            </div>
        )
    }

    return (
        <>
            <TableFilters
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                searchPlaceholder={settingsWeb.ruleForm.searchPlaceholder()}
                groups={groupsNames}
                groupsFilter={groupsFilter}
                setGroupsFilter={setGroupsFilter}
                syncedLabel={settingsWeb.ruleForm.syncedLabel()}
                groupsPlaceholder={settingsWeb.ruleForm.groupLabel()}
                synced={syncedFilter}
                setSynced={setSyncedFilter}
            />
            {formAreas.length > 0 && <UI.Text weight="semibold">{settingsWeb.rules.selectedCount({ count: formAreas.length })}</UI.Text>}
            <Table table={table} />
        </>
    )
}
